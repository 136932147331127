'use client';

import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import ExpandMore from '@mui/icons-material/ExpandMore';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
    xxxl: true;
  }
}

const units = {
  borderRadius: 4,
  spacing: 8,
};

// Define all colors in a single object for consistency
const colors = {
  white: '#FFFFFF',
  main: '#1C1C1C',
  gold: '#C5A572',
  info: '#2E7DD1',
  warning: '#F59E0B',
  error: '#DC2626',
  success: '#059669',
  textPrimary: '#1C1C1C',
  textSecondary: '#666666',
  divider: 'rgba(0, 0, 0, 0.12)',
  shadow1: 'rgba(0, 0, 0, 0.03)',
  shadow2: 'rgba(0, 0, 0, 0.02)',
  shadow3: 'rgba(0, 0, 0, 0.01)',
};

const theme = createTheme({
  cssVariables: true,
  colorSchemes: {
    light: {
      palette: {
        contrastThreshold: 4.5,
        common: {
          white: colors.white,
          black: colors.main,
        },
        primary: {
          main: colors.main,
          contrastText: colors.white,
        },
        secondary: {
          main: colors.gold,
          contrastText: colors.main,
        },
        divider: colors.divider,
        background: {
          default: '#F9F9F9',
          paper: colors.white,
        },
        text: {
          primary: colors.textPrimary,
          secondary: colors.textSecondary,
        },
        info: {
          main: colors.info,
        },
        warning: {
          main: colors.warning,
        },
        error: {
          main: colors.error,
        },
        success: {
          main: colors.success,
        },
      },
    },
  },
  shape: {
    borderRadius: units.borderRadius,
  },
  spacing: units.spacing,
  shadows: [
    'none',
    `0px 2px 1px -1px ${colors.shadow1},0px 1px 1px 0px ${colors.shadow2},0px 1px 3px 0px ${colors.shadow3}`,
    `0px 3px 1px -2px ${colors.shadow1},0px 2px 2px 0px ${colors.shadow2},0px 1px 5px 0px ${colors.shadow3}`,
    `0px 3px 3px -2px ${colors.shadow1},0px 3px 4px 0px ${colors.shadow2},0px 1px 8px 0px ${colors.shadow3}`,
    `0px 2px 4px -1px ${colors.shadow1},0px 4px 5px 0px ${colors.shadow2},0px 1px 10px 0px ${colors.shadow3}`,
    `0px 3px 5px -1px ${colors.shadow1},0px 5px 8px 0px ${colors.shadow2},0px 1px 14px 0px ${colors.shadow3}`,
    `0px 3px 5px -1px ${colors.shadow1},0px 6px 10px 0px ${colors.shadow2},0px 1px 18px 0px ${colors.shadow3}`,
    `0px 4px 5px -2px ${colors.shadow1},0px 7px 10px 1px ${colors.shadow2},0px 2px 16px 1px ${colors.shadow3}`,
    `0px 5px 5px -3px ${colors.shadow1},0px 8px 10px 1px ${colors.shadow2},0px 3px 14px 2px ${colors.shadow3}`,
    `0px 5px 6px -3px ${colors.shadow1},0px 9px 12px 1px ${colors.shadow2},0px 3px 16px 2px ${colors.shadow3}`,
    `0px 6px 6px -3px ${colors.shadow1},0px 10px 14px 1px ${colors.shadow2},0px 4px 18px 3px ${colors.shadow3}`,
    `0px 6px 7px -4px ${colors.shadow1},0px 11px 15px 1px ${colors.shadow2},0px 4px 20px 3px ${colors.shadow3}`,
    `0px 7px 8px -4px ${colors.shadow1},0px 12px 17px 2px ${colors.shadow2},0px 5px 22px 4px ${colors.shadow3}`,
    `0px 7px 8px -4px ${colors.shadow1},0px 13px 19px 2px ${colors.shadow2},0px 5px 24px 4px ${colors.shadow3}`,
    `0px 7px 9px -4px ${colors.shadow1},0px 14px 21px 2px ${colors.shadow2},0px 5px 26px 4px ${colors.shadow3}`,
    `0px 8px 9px -5px ${colors.shadow1},0px 15px 22px 2px ${colors.shadow2},0px 6px 28px 5px ${colors.shadow3}`,
    `0px 8px 10px -5px ${colors.shadow1},0px 16px 24px 2px ${colors.shadow2},0px 6px 30px 5px ${colors.shadow3}`,
    `0px 8px 11px -5px ${colors.shadow1},0px 17px 26px 2px ${colors.shadow2},0px 6px 32px 5px ${colors.shadow3}`,
    `0px 9px 11px -5px ${colors.shadow1},0px 18px 28px 2px ${colors.shadow2},0px 7px 34px 6px ${colors.shadow3}`,
    `0px 9px 12px -6px ${colors.shadow1},0px 19px 29px 2px ${colors.shadow2},0px 7px 36px 6px ${colors.shadow3}`,
    `0px 10px 13px -6px ${colors.shadow1},0px 20px 31px 3px ${colors.shadow2},0px 8px 38px 7px ${colors.shadow3}`,
    `0px 10px 13px -6px ${colors.shadow1},0px 21px 33px 3px ${colors.shadow2},0px 8px 40px 7px ${colors.shadow3}`,
    `0px 10px 14px -6px ${colors.shadow1},0px 22px 35px 3px ${colors.shadow2},0px 8px 42px 7px ${colors.shadow3}`,
    `0px 11px 14px -7px ${colors.shadow1},0px 23px 36px 3px ${colors.shadow2},0px 9px 44px 8px ${colors.shadow3}`,
    `0px 11px 15px -7px ${colors.shadow1},0px 24px 38px 3px ${colors.shadow2},0px 9px 46px 8px ${colors.shadow3}`,
  ],
  breakpoints: {
    values: {
      xxxl: 1920,
      xxl: 1536,
      xl: 1440,
      lg: 1280,
      md: 1024,
      sm: 600,
      xs: 0,
    },
  },
  typography: {
    fontFamily: 'var(--font-regular)',
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
        variant: 'elevation',
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
          borderBottom: `1px solid ${colors.divider}`,
          backgroundColor: 'rgba(255, 255, 255, 0.98)',
          backdropFilter: 'blur(10px)',
        },
      },
    },
    MuiListItemText: {
      defaultProps: {
        slotProps: {
          primary: {
            variant: 'body1',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: `${units.spacing}px ${units.spacing * 2}px`,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          position: 'relative',
          transition: 'all 0.2s ease-in-out',
          '&::after': {
            content: '""',
            position: 'absolute',
            inset: 0,
            borderRadius: 'inherit',
            opacity: 0,
            transition: 'opacity 0.2s ease-in-out',
            background:
              'radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 70%)',
            pointerEvents: 'none',
          },
          '&:active::after': {
            opacity: 0.3,
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
        IconComponent: ExpandMore,
      },
      styleOverrides: {
        root: {
          borderRadius: units.borderRadius,
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.01)',
          },
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          borderRadius: units.borderRadius,
          border: `1px solid ${colors.divider}`,
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            borderColor: colors.gold,
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: 'outlined',
        size: 'small',
        slotProps: {
          inputLabel: {
            shrink: true,
            sx: { letterSpacing: '0.02em' },
          },
        },
      },
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
          borderRadius: units.borderRadius,
          '& .MuiOutlinedInput-root': {
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.01)',
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          border: `1px solid ${colors.divider}`,
          borderRadius: units.borderRadius,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
      styleOverrides: {
        root: {
          display: 'inline-flex',
          alignItems: 'center',
          transition: 'all 0.2s ease-in-out',
          '&:hover': {
            opacity: 0.8,
          },
          '&.MuiTypography-body1 > svg': {
            marginTop: 2,
          },
          '& svg:last-child': {
            marginLeft: 2,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: units.borderRadius,
          textTransform: 'none',
          padding: 'var(--mui-spacing) calc(var(--mui-spacing) * 1.5)',
          transition: 'all 0.3s ease-in-out',
          letterSpacing: '0.03em',
        },
        contained: {
          '&:hover': {
            transform: 'translateY(-2px)',
          },
        },
        outlined: {
          borderWidth: '1.5px',
          '&:hover': {
            borderWidth: '1.5px',
            backgroundColor: 'rgba(0, 0, 0, 0.02)',
          },
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          '& .MuiPopover-paper': {
            backgroundColor: colors.white,
            border: `1px solid ${colors.divider}`,
            borderRadius: units.borderRadius,
          },
        },
      },
    },
  },
});

const responsiveTheme = responsiveFontSizes(theme);

export default responsiveTheme;
