'use client';

import { useEffect } from 'react';
import { onAuthStateChanged } from '@/lib/firebase/auth/utils';
import { useRouter } from 'next/navigation';
import { COOKIE_NAME } from '@/lib/firebase/constants';
import useAuth from '@/hooks/useAuth';
import type { UserRecord } from '@/types/schemas';

export default function useUser<T>(userData: Partial<T & UserRecord> | null) {
  const { getUserData, setUserData } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (userData) {
      setUserData(userData);
    }
  }, [userData, setUserData]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(async (authUser) => {
      if (authUser) {
        const token = await authUser.getIdToken(true);
        await getUserData();
        document.cookie = `${COOKIE_NAME}=${token}; path=/;`;
      } else {
        document.cookie = `${COOKIE_NAME}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        setUserData(null);
      }

      router.refresh();
    });

    return () => unsubscribe();
  }, [setUserData, router, getUserData]);
}
