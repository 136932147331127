'use client';

import type { AuthClientProps } from '@/lib/firebase/types';
import useUser from '@/lib/firebase/hooks/useUser';
import type { UserData } from '@/types/schemas';

export default function AuthClient({ children, userData }: AuthClientProps<UserData>) {
  useUser<UserData>(userData);

  return <>{children}</>;
}
